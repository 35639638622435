import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PlayIcon } from "@heroicons/react/solid";
import Button from "components/ui/button";
import Modal from "components/modal";
import Video from "components/video";

export const Hero = () => {
  const ModalVideo = useRef();
  return (
    <React.Fragment>
      <Modal ref={ModalVideo}>
        <Video
          videoSrcURL="https://player.vimeo.com/video/691758937?autoplay=1"
          videoTitle="Features Of Site Inspection Reporting"
          classes="full-screen"
        />
      </Modal>
      <section className="border-b relative overflow-hidden">
        <div className="absolute -right-32 -top-20 sm:-right-52 sm:-top-48 w-full sm:w-[700px]">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 649 636"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.7593 228.63C60.5163 142.271 106.15 66.0463 176.229 13.9885L178.721 17.3447C35.8794 123.416 5.96896 325.894 112.04 468.735C163.423 537.93 238.676 582.975 323.914 595.566C409.152 608.158 494.235 586.799 563.41 535.412L565.902 538.769C495.802 590.823 409.642 612.447 323.284 599.69C236.925 586.933 160.7 541.3 108.643 471.221C56.6263 401.148 35.0022 314.988 47.7593 228.63Z"
              fill="#F4F4F4"
            />
            <path
              d="M70.1369 231.935C81.5276 154.827 122.261 82.0738 189.624 32.0518L192.116 35.408C59.2479 134.073 31.4207 322.448 130.083 455.337C228.748 588.205 417.144 616.035 550.012 517.37L552.504 520.726C417.779 620.77 226.771 592.554 126.706 457.826C76.7047 390.467 58.7493 309.023 70.1369 231.935Z"
              fill="#F4F4F4"
            />
            <path
              d="M92.245 235.201C103.247 160.726 142.604 94.9966 203.042 50.0975L205.535 53.4537C145.98 97.6781 107.232 162.438 96.3959 235.793C85.5567 309.169 103.946 382.369 148.152 441.9C192.377 501.455 257.137 540.203 330.492 551.039C403.867 561.878 477.068 543.489 536.599 499.282L539.091 502.639C478.656 547.517 404.333 566.185 329.858 555.184C255.384 544.182 189.654 504.825 144.755 444.386C99.9148 383.978 81.2434 309.676 92.245 235.201Z"
              fill="#F4F4F4"
            />
            <path
              d="M114.623 238.507C124.328 172.805 159.046 110.798 216.458 68.164L218.95 71.5202C105.982 155.409 82.3242 315.557 166.213 428.526C250.101 541.495 410.25 565.152 523.219 481.264L525.711 484.62C410.885 569.887 248.103 545.84 162.856 431.018C120.223 373.606 104.917 304.209 114.623 238.507Z"
              fill="#F4F4F4"
            />
            <path
              d="M136.855 241.791C145.718 181.792 177.418 125.146 229.856 86.2065L232.349 89.5628C129.329 166.063 107.758 312.088 184.261 415.086C260.761 518.105 406.787 539.677 509.785 463.173L512.277 466.53C407.425 544.391 258.746 522.427 180.884 417.576C141.96 365.182 127.992 301.79 136.855 241.791Z"
              fill="#F4F4F4"
            />
            <path
              d="M159.088 245.075C167.111 190.759 195.812 139.497 243.251 104.27L245.744 107.626C152.698 176.72 133.21 308.643 202.304 401.688C235.782 446.771 284.808 476.116 340.347 484.321C395.887 492.525 451.304 478.608 496.387 445.131L498.879 448.487C452.913 482.639 396.377 496.815 339.738 488.448C283.099 480.081 233.079 450.144 198.948 404.181C163.72 356.741 151.064 299.391 159.088 245.075Z"
              fill="#F4F4F4"
            />
            <path
              d="M181.32 248.36C188.501 199.747 214.182 153.867 256.67 122.316L259.163 125.672C176.067 187.377 158.665 305.176 220.371 388.272C282.076 471.368 399.875 488.77 482.971 427.064L485.463 430.421C400.511 493.505 280.078 475.714 216.994 390.762C185.478 348.321 174.139 296.972 181.32 248.36Z"
              fill="#F4F4F4"
            />
            <path
              d="M595.487 441.354L585.309 427.623L597.254 429.388L595.487 441.354Z"
              fill="#F4F4F4"
            />
            <path
              d="M592.215 463.504L563.138 424.348L575.084 426.113L593.979 451.558L592.215 463.504Z"
              fill="#F4F4F4"
            />
            <path
              d="M582.417 529.828L496.835 414.554L508.594 416.291L584.157 518.048L582.417 529.828Z"
              fill="#F4F4F4"
            />
            <path
              d="M585.665 507.845L518.818 417.801L530.764 419.566L587.429 495.899L585.665 507.845Z"
              fill="#F4F4F4"
            />
            <path
              d="M588.94 485.674L540.968 421.073L552.935 422.841L590.704 473.728L588.94 485.674Z"
              fill="#F4F4F4"
            />
          </svg>
        </div>
        <div className="sir-container h-screen flex flex-col">
          <div className="h-20"></div>
          <div className="flex h-full items-center justify-center lg:flex-row flex-col lg:space-x-10 lg:space-x-reverse">
            <div className="-mx-5 mb-10 lg:mb-0 lg:order-2 relative w-11/12 sm:w-9/12 lg:w-full">
              <div className="absolute top-0 bottom-0 left-0 right-0 m-auto z-20 flex justify-center items-center">
                <button onClick={() => ModalVideo.current.openModal()}>
                  <PlayIcon className="h-12 w-12 sm:h-24 sm:w-24 text-sir-secondary opacity-90" />
                </button>
              </div>
              <div className="relative z-10">
                <StaticImage
                  src="../../images/hero-img.png"
                  alt="Hero Image"
                  placeholder="blurred"
                />
              </div>
            </div>
            <div className="space-y-6 text-left lg:order-1 lg:w-11/12">
              <h1 className="text-3xl font-bold lg:text-5xl">
                Create Digital Reports, Inspections &amp; Checklists
              </h1>
              <p>
                Create paperless custom reports, inspections &amp; checklists in
                minutes, share with colleges and customers in a flash
              </p>
              <div>
                <a
                  href="https://app.siteinspectionreporting.com.au/register"
                  id="cta_login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    name="Try It Free"
                    classes="bg-sir-secondary w-auto h-12"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="hidden">
            <ul className="h-20 flex space-x-10 grayscale opacity-30 justify-center items-center">
              <li>
                <StaticImage
                  src="../../images/logo/aquest.png"
                  alt="aquest"
                  height={40}
                />
              </li>
              <li>
                <StaticImage
                  src="../../images/logo/obrien.png"
                  alt="obrien"
                  height={40}
                />
              </li>
              <li>
                <StaticImage
                  src="../../images/logo/kelly.png"
                  alt="kelly"
                  height={40}
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
