import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const HowItWorks = () => {
  return (
    <section className="my-20">
      <div className="sir-container">
        <div className="space-y-2 text-center">
          <h2 className="text-3xl font-bold lg:text-4xl">How It Works</h2>
          <p className="px-10">
            Building Paperless Inspections, Checklists &amp; Reports
          </p>
        </div>
        <div className="-mx-5 my-10 lg:mx-0">
          <div className="relative">
            <div className="hidden absolute top-0 mt-10 sm:mt-20 bottom-0 m-auto w-5/12 lg:w-7/12 z-20 sm:flex justify-center items-center right-0 left-0">
              <StaticImage
                src="../../images/direction-arrow.svg"
                alt="illustration"
              />
            </div>
            <div className="flex items-center justify-center lg:space-x-5">
              <div className="-mr-16 sm:mr-0 relative z-30 lg:mt-16">
                <StaticImage
                  src="../../images/hiw-build-img.png"
                  alt="illustration"
                />
              </div>
              <div className="z-30">
                <StaticImage
                  src="../../images/hiw-complete-img.png"
                  alt="illustration"
                />
              </div>
              <div className="z-10 -ml-16 sm:ml-0">
                <StaticImage
                  src="../../images/hiw-share-img.png"
                  alt="illustration"
                />
              </div>
            </div>
          </div>
        </div>
        <ol className="space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
          <li className="flex space-x-3 text-left ">
            <div className="text-6xl font-bold flex-none w-10">1</div>
            <div className="space-y-1">
              <h3 className="text-3xl font-bold text-sir-secondary lg:text-4xl">
                Build
              </h3>
              <p>
                Any type of customised report – inspection or form with our
                dynamic template builder
              </p>
            </div>
          </li>
          <li className="flex space-x-3 text-left">
            <div className="text-6xl font-bold flex-none w-10">2</div>
            <div className="space-y-1">
              <h3 className="text-3xl font-bold text-sir-secondary lg:text-4xl">
                Complete
              </h3>
              <p>
                Any type of custom report, inspection, or checklist with or with
                connectivity – all in real-time
              </p>
            </div>
          </li>
          <li className="flex space-x-3 text-left">
            <div className="text-6xl font-bold flex-none w-10">3</div>
            <div className="space-y-1">
              <h3 className="text-3xl font-bold text-sir-secondary lg:text-4xl">
                Share
              </h3>
              <p>
                Paperless digital reports to your office colleges, office or
                customers instantly
              </p>
            </div>
          </li>
        </ol>
      </div>
    </section>
  );
};
