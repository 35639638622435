import React from "react";
import { Link } from "gatsby";
import Button from "components/ui/button";
import { StaticImage } from "gatsby-plugin-image";
import { PaperClipIcon } from "@heroicons/react/outline";

export const Support = () => {
  return (
    <div className="bg-sir-mediumGray border-t border-solid border-color-sir-lightGray py-20 lg:py-32">
      <div className="sir-container">
        <div className="flex flex-col lg:flex-row items-center lg:space-x-20 space-y-10 space-y-reverse lg:space-y-0">
          <div className="order-2 lg:order-1 mr-auto w-6/12 sm:w-8/12">
            <div className="rounded-xl overflow-hidden lg:w-auto ">
              <StaticImage
                src="../../images/support-img.jpg"
                alt="Support Image"
              />
            </div>
          </div>
          <div className="order-1 lg:order-2">
            <h2 className="mb-5 text-3xl font-bold lg:text-4xl">
              S.I.R Support
            </h2>
            <div className="mb-6">
              Need help getting started our support team is ready to create a
              digital checklist, form, or report.
            </div>
            <ol className="flex flex-col space-y-4 mb-8 list-decimal list-outside ml-6 marker:font-bold">
              <li>Create a Free Site Inspection Reporting Account.</li>
              <li>
                Upload your file via support ticket and our S.I.R support team
                will convert up to 3 forms into a digital template.
              </li>
              <li>
                You'll be notified when your templates are ready for you to
                inspect, and share professional reports with your team, office,
                or clients.
              </li>
            </ol>

            <Link to="/contact-us">
              <Button
                name="Upload your Template Form"
                classes="bg-sir-primary h-12"
                icon={PaperClipIcon}
              ></Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
