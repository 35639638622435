import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const TryFree = () => {
  return (
    <section className="bg-sir-primary">
      <div className="sir-container">
        <div className="py-24 text-white md:flex md:flex-row md:items-center md:justify-center md:space-x-20">
          <div className="mb-8 space-y-2 md:mb-0 text-center">
            <div>
              <a
                href="https://app.siteinspectionreporting.com.au/register"
                id="cta_login"
                target="_blank"
                rel="noreferrer"
              >
                <button className="rounded-full border border-dashed py-2 px-8 text-2xl">
                  <strong>
                    Try It{" "}
                    <span className="uppercase text-sir-secondary">Free</span>
                  </strong>
                </button>
              </a>
            </div>
            <div className="relative -left-10 m-auto">
              <StaticImage
                src="../../images//arrow-amzn.svg"
                alt="illustration"
              />
            </div>
          </div>
          <ul className="space-y-5 sm:space-y-3 text-left text-xl">
            <li className="flex space-x-3">
              <div className="pt-1 flex-none">
                <StaticImage
                  src="../../images//circle-check.svg"
                  alt="bullets"
                />
              </div>
              <div>
                Single user account is{" "}
                <strong className="border-b border-dashed border-slate-600">
                  completely free to use.
                </strong>
              </div>
            </li>
            <li className="flex space-x-3">
              <div className="pt-1 flex-none">
                <StaticImage
                  src="../../images//circle-check.svg"
                  alt="bullets"
                />
              </div>
              <div>
                <strong className="border-b border-dashed border-slate-600">
                  No credit card
                </strong>
                is required when creating your account
              </div>
            </li>
            <li className="flex space-x-3">
              <div className="pt-1 flex-none">
                <StaticImage
                  src="../../images//circle-check.svg"
                  alt="bullets"
                />
              </div>
              <div>
                <strong className="border-b border-dashed border-slate-600">
                  No trial period
                </strong>
              </div>
            </li>
            <li className="flex space-x-3">
              <div className="pt-1 flex-none">
                <StaticImage
                  src="../../images//circle-check.svg"
                  alt="bullets"
                />
              </div>
              <div>
                <strong className="border-b border-dashed border-slate-600">
                  All SIR features included
                </strong>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
