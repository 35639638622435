import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import ListAdvantage from "./advantage/listAdvantage";
import freeIcon from "images/icon-free.png";
import customIcon from "images/icon-custom.svg";
import paperlessIcon from "images/icon-paperless.svg";
import captureIcon from "images/icon-capture.png";
import cloudIcon from "images/icon-cloud.svg";
import reportigIcon from "images/icon-reporting.svg";
import Button from "components/ui/button";
import Modal from "components/modal";
import Video from "components/video";

export const Advantages = () => {
  const ModalVideo = useRef();
  return (
    <React.Fragment>
      <Modal ref={ModalVideo}>
        <Video
          videoSrcURL="https://player.vimeo.com/video/691758937?autoplay=1"
          videoTitle="Features Of Site Inspection Reporting"
          classes="full-screen"
        />
      </Modal>

      <section className="py-20 sm:py-32 px-5 text-left sm:px-10">
        <div className="sir-container">
          <div className="lg:mb-10 lg:flex lg:flex-row lg:space-x-20">
            <div>
              <h2 className="mb-5 text-3xl font-bold lg:text-4xl">
                Advantages &amp; Benefits
              </h2>
              <ul className="space-y-4 lg:space-y-6">
                <ListAdvantage
                  icon={freeIcon}
                  text="Single User <strong>FREE Account</strong> - use the site inspection app at no cost!*"
                />
                <ListAdvantage
                  icon={customIcon}
                  text="<strong>Fully Customisable</strong> - build a report your own way with powerful features to create and deploy dynamic forms."
                />
                <ListAdvantage
                  icon={paperlessIcon}
                  text="<strong>Paperless Reports</strong> - share reports in PDF file straight to anywhere it needs to go"
                />
                <ListAdvantage
                  icon={captureIcon}
                  text="<strong>Capture Image</strong> - ability to attach multiple photos specific to each task"
                />
                <ListAdvantage
                  icon={cloudIcon}
                  text="<strong>Cloud Database / Storage</strong> - safely store,  conveniently share, and access data anywhere you are on any device"
                />
                <ListAdvantage
                  icon={reportigIcon}
                  text="<strong>White Label Reporting</strong> - label reports under your organisation, clients or third-party branding"
                />
              </ul>
            </div>
            <div className="my-10 lg:my-0 lg:flex lg:items-center">
              <div className="sm:m-auto sm:w-7/12 lg:w-full">
                <StaticImage
                  src="../../images/advantage+benefits.png"
                  alt="illustration"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 sm:justify-center lg:justify-start">
            <div>
              <a
                href="https://app.siteinspectionreporting.com.au/register"
                id="cta_login"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  name="Try It Free"
                  classes="bg-sir-secondary w-auto h-12"
                />
              </a>
            </div>
            <button
              className="flex items-center space-x-2"
              onClick={() => ModalVideo.current.openModal()}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10"
                  viewBox="0 0 20 20"
                  fill="#D9D9D9"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="text-xs opacity-50 text-left">
                Watch our <span className="block">app demo video</span>
              </div>
            </button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
