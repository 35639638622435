import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "components/ui/button";

export const Performing = () => {
  return (
    <section className="my-20">
      <div className="sir-container">
        <div className="items-center space-y-7 text-left sm:flex sm:flex-col lg:flex-row">
          <div className="space-y-7">
            <h2 className="mb-3 text-3xl font-bold lg:text-4xl">
              Performing An Inspection
            </h2>
            <p>
              Perform and complete any type of site inspection with multiple
              images, feedback notes with or without connectivity has never been
              faster
            </p>
            <div className="flex items-start space-x-3">
              <div className="mt-1 block flex-none">
                <StaticImage
                  src="../../images/icon-clock.svg"
                  alt="icon clock"
                  quality={100}
                />
              </div>
              <div>
                <p>Save hrs double handling to create the perfect report. </p>
                <p className="text-xs">
                  More efficient and compliant than paper reports
                </p>
              </div>
            </div>

            <div>
              <a
                href="https://app.siteinspectionreporting.com.au/register"
                id="cta_login"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  name="Try It Free"
                  classes="bg-sir-secondary w-auto h-12"
                />
              </a>
            </div>
          </div>
          <div className="h-50 relative pt-5">
            <div className="relative z-20 flex w-11/12 items-end justify-center sm:m-auto sm:w-8/12">
              <div className="w-11/12">
                <StaticImage
                  src="../../images/pai-mobile.png"
                  alt="mobile image"
                />
              </div>
              <div className="w-8/12 space-y-1">
                <div className="ml-1 w-8/12">
                  <StaticImage
                    src="../../images/pai-capture-image.png"
                    alt="capture image"
                  />
                </div>
                <StaticImage
                  src="../../images/pai-upload-image.png"
                  alt="upload image"
                />
              </div>
            </div>
            <div className="absolute -right-5 top-0 flex h-full items-center justify-end overflow-hidden">
              <div className="relative -right-10 z-10 w-10/12 sm:w-7/12">
                <StaticImage
                  src="../../images/pai-stock-photo.png"
                  alt="stock photo"
                />
              </div>
              <div className="absolute top-0 -right-5 w-7/12 overflow-hidden">
                <div className="relative -right-20">
                  <StaticImage
                    src="../../images/illustration-circle-stripes.png"
                    alt="illustration"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
