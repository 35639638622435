import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { UserCircleIcon } from "@heroicons/react/solid";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import "swiper/css";
import "swiper/css/pagination";

const ReviewsArgs = [
  {
    name: "Jodie H",
    message:
      "Having the ability to build custom report templates creates consistency for our team and clients. If someone is sick or leaves it's an easy transition for the next team member, on site they know exactly what to do.",
  },
  {
    name: "William T",
    message:
      "Switching from paper to online is new to me. I was skeptical at first because I'm not a techy person but I got the hang of it easily. The office and client can see my report right away and I dont need to send photos separately anymore. All that I need is here.",
  },
  {
    name: "Anton B",
    message:
      "Been using this platform for a couple of weeks. I am a contractor myself and all the basic functions are here. It makes my work easier and more efficient. No drama, plus I can use it for free. So far so good!",
  },
  {
    name: "Jenny A",
    message:
      "This was referred by a friend and I have no regret using site inspection reporting.  Unlike other reporting apps that bill you so much to sign up, this works offline, customisable and free.",
  },
  {
    name: "Mohamed R",
    message:
      "As a contract cleaner I really like the way I can do an end of lease clean and submit my report, with images and include my real-estate client company details on the report which they can send on to the landlord and tenant saving them 2hrs.  This feature has helped me win more clients.",
  },
];

const Reviews = () => {
  return (
    <React.Fragment>
      <div className="sir-container">
        <div className="custom-controller z-30 sm:flex justify-end mb-10 space-x-3 text-white hidden">
          <button className="swiper-button-prev">
            <ChevronLeftIcon className="currentColor stroke-1 inline-block h-8 w-8" />
          </button>
          <button className="swiper-button-next">
            <ChevronRightIcon className="currentColor stroke-1 inline-block h-8 w-8" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: "auto",
              spaceBetween: 15,
              navigation: false,
            },
            768: {
              slidesPerView: 2,
              pagination: false,
            },
            1024: {
              slidesPerView: 3,
              pagination: false,
            },
          }}
        >
          {ReviewsArgs.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="space-y-5 rounded-2xl text-black bg-sir-lightGray p-7 text-left">
                <div className="flex items-center space-x-3">
                  <UserCircleIcon className="h-12 w-12 text-gray-300" />
                  <div className="font-bold ">{item.name}</div>
                </div>
                <p>{item.message}</p>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </React.Fragment>
  );
};

export default Reviews;
