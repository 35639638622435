import React from "react";
import Seo from "components/seo";
import Layout from "components/layout";
import {
  Hero,
  ReviewsSection,
  HowItWorks,
  TryFree,
  Performing,
  OutputPdf,
  Support,
  Advantages,
} from "sections/homepage";

const Homepage = () => {
  return (
    <React.Fragment>
      <Layout>
        <Seo
          title="Building Inspection Reports"
          description="Building Inspection Report Made Easy, Best for vehicle inspection report, rental inspection checklist, house inspection checklist and inspection report template. Try it FREE!"
        />
        <Hero />
        <HowItWorks />
        <TryFree />
        <Performing />
        <OutputPdf />
        <Advantages />
        <Support />
        <ReviewsSection />
      </Layout>
    </React.Fragment>
  );
};

export default Homepage;
