import React from "react";

const ListAdvantage = (props) => {
  return (
    <li className="flex space-x-3 items-start sm:items-center">
      <div className="pt-1 w-12 flex-none">
        <img src={props.icon} alt="illustration" className="w-[32px]" />
      </div>
      <div>
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </li>
  );
};

export default ListAdvantage;
