import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Reviews from "components/reviews";

export const ReviewsSection = () => {
  return (
    <section className="bg-sir-primary py-20 sm:py-32 px-5 text-center text-white sm:px-10">
      <div className="sm:flex sm:space-x-10 lg:justify-center">
        <div className="mb-5 space-y-1">
          <h2 className="text-3xl font-bold lg:text-4xl">
            Happy Clients, <span className="sm:block">Great Reviews</span>
          </h2>
          <div className="m-auto">
            <StaticImage src="../../images/draw-lines.svg" alt="illustration" />
          </div>
        </div>
        <div className="sm:text-md sm:pt-7 sm:text-left sm:font-medium">
          Learn about Site Inspection Reports from other users.
        </div>
      </div>
      <div className="mt-14 sm:mt-0">
        <Reviews />
      </div>
    </section>
  );
};
