import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "components/ui/button";

export const OutputPdf = () => {
  return (
    <section className="bg-sir-primary">
      <div className="sir-container">
        <div className="relative py-20 sm:py-32 text-left text-white lg:flex lg:flex-row lg:items-center lg:space-x-20 lg:space-x-reverse">
          <div className="mb-7 space-y-7 lg:order-2">
            <div>
              <h2 className="mb-3 text-3xl font-bold lg:text-4xl">
                Build to your own custom reports
              </h2>
              <p>
                Map any type of report template with SIRs dynamic template
                builder and share the perfect report from any device while
                onsite to your colleges, offices, or customers instantly.
              </p>
            </div>
            <div>
              <a
                href="https://app.siteinspectionreporting.com.au/register"
                id="cta_login"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  name="Try It Free"
                  classes="bg-sir-secondary w-auto h-12"
                />
              </a>
            </div>
          </div>
          <div className="relative sm:m-auto sm:w-8/12 lg:order-1 lg:w-11/12">
            <div className="absolute top-0 w-10/12">
              <StaticImage
                src="../../images/pdf-section-main-pdf.png"
                alt="illustration"
              />
            </div>
            <div className="relative z-10 ml-auto w-8/12 pt-14">
              <div className="ml-auto w-6/12">
                <StaticImage
                  src="../../images/pdf-section-download.png"
                  alt="illustration"
                />
              </div>
              <div className="ml-auto w-8/12">
                <StaticImage
                  src="../../images/pdf-section-share.png"
                  alt="illustration"
                />
              </div>
              <StaticImage
                src="../../images/pdf-section-img-form.png"
                alt="illustration"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
